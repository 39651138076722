<script setup lang="ts"></script>

<template>
  <section class="bg-white dark:bg-gray-900">
    <div class="grid h-screen lg:grid-cols-2 lg:py-0">
      <div class="flex items-center justify-center px-4 py-6 sm:px-0 lg:py-0">
        <div
          class="w-full rounded dark:border-gray-700 dark:bg-gray-800 sm:max-w-md md:mt-0 xl:p-0"
        >
          <!--          <div class="space-y-4 p-6 sm:p-8 md:space-y-6">-->
          <a
            href="#"
            class="mb-6 flex items-center text-2xl font-semibold text-gray-900 dark:text-white"
          >
            <img
              class="mr-2 h-8 fill-gray-900"
              src="~/assets/img/unihomes_logo.svg"
              alt="UniHomes Logo"
            />
          </a>
          <slot></slot>
          <!--          </div>-->
        </div>
      </div>
      <div class="hidden overflow-hidden bg-blue-500 lg:block">
        <img
          class="h-full w-full object-cover object-top lg:object-left"
          src="~/assets/img/unihomes-agent-portal.webp"
          alt="Login graphic"
        />
      </div>
    </div>
  </section>
</template>
